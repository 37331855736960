<template>
  <div>
    <FullscreenLoader v-if="!user" />

    <template v-else>
      <h2 class="oleo">Contactvoorstellen</h2>
      <p>
        Hier vind je een overzicht van de contactvoorstellen die je ontvangen
        hebt. Klik door om het voorstel te bekijken.
      </p>

      <div class="header-bar">Contactvoorstellen</div>
      <table class="mijnps-table">
        <tbody>
          <tr
            v-for="voorstel in voorstellen"
            :key="voorstel.Voorstelnummer"
            @click="$router.push(`/voorstellen/${voorstel.Voorstelnummer}`)"
            class="table-clickable"
          >
            <td
              :style="{
                fontWeight: voorstel.Openstaand === '1' ? 'bold' : 'normal',
              }"
            >
              {{ voorstel.Voornaam }}
            </td>
            <td style="text-transform: capitalize">
              {{ toonVoorstelDatum(voorstel.DatumA) }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="voorstel-cards">
        <template v-for="voorstel in voorstellen">
          <Card
            :key="voorstel.Voorstelnummer"
            :openstaand="voorstel.Openstaand"
            :voornaam="voorstel.Voornaam"
            :profielFoto="voorstel.foto_p2"
            :datum="voorstel.DatumA"
            :voorstelNummer="voorstel.Voorstelnummer"
            :fotocode="voorstel.Foto_code"
          ></Card>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  toonKorteDatum,
  toonLangeDatum,
  toonVoorstelDatum,
} from "../functions/toonDatum";
import Card from "../components/Card";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user", "voorstellen"]),
  },
  components: {
    Card,
  },
  methods: {
    toonKorteDatum,
    toonLangeDatum,
    toonVoorstelDatum,
  },
  mounted() {
    scrollTo(0, 0);
  },
};
</script>