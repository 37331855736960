<template>
  <div class="card">
    <template>
      <img
        v-if="fotocode == '1'"
        :src="profielFoto"
        alt="Profielfoto niet gevonden"
        class="card__foto"
        load="lazy"
        @click="$router.push(`/voorstellen/${voorstelNummer}`)"
      />
      <div
        class="card__foto"
        v-else
        style="display: flex; justify-content: center; align-items: center"
        @click="$router.push(`/voorstellen/${voorstelNummer}`)"
      >
        <h4 class="oleo" style="display: block">
          Foto {{ fotocode == "0" ? "nog " : "" }}niet zichtbaar
        </h4>
      </div>
    </template>
    <div class="card__content">
      <h4 class="oleo card__naam">{{ voornaam }}</h4>
      <p
        class="card__datum"
        :style="{ 'font-weight': openstaand === '1' ? 'bold' : 'normal' }"
      >
        Verzonden: {{ toonVoorstelDatum(datum) }}
      </p>
      <button
        class="btn btn-rounded card__btn"
        @click="$router.push(`/voorstellen/${voorstelNummer}`)"
        :style="{ 'font-weight': openstaand === '1' ? 'bold' : 'normal' }"
      >
        Bekijk het voorstel
      </button>
    </div>
  </div>
</template>

<script>
import {
  toonLangeDatum,
  toonKorteDatum,
  toonVoorstelDatum,
} from "../functions/toonDatum";

export default {
  data() {
    return {};
  },
  props: [
    "voorstelNummer",
    "profielFoto",
    "voornaam",
    "datum",
    "openstaand",
    "fotocode",
  ],
  methods: {
    toonLangeDatum,
    toonKorteDatum,
    toonVoorstelDatum,
  },
};
</script>